// HomePeople.js
import React from "react";
import Header from "../componentsProyectum/Header";
import BarraApp from "../componentsPeopletech/Barraaps"; // Asegúrate de que sea el nombre correcto
import Footer from "../componentsProyectum/Footer/Footer";

const HomeProyectum = () => {
  return (
    <>
      <BarraApp />
      <Header /> {/* Componente de encabezado */}
      <Footer />
    </>
  );
};

export default HomeProyectum;
