import React from "react";
import GetInTouch from "../components/GetInTouch";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import BarraApp from "../componentsPeopletech/Barraaps";
const Home = () => {
  return (
    <>
      <BarraApp />
      <Header />
      <GetStarted />
      <Footer />
    </>
  );
};

export default Home;
