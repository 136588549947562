// HomePeople.js
import React from "react";
import Header from "../componentsPeopletech/Header";
import BarraApp from "../componentsPeopletech/Barraaps"; // Asegúrate de que sea el nombre correcto
import Footer from "../componentsPeopletech/Footer/Footer";

const HomePeople = () => {
  return (
    <>
      <BarraApp />
      <Header /> {/* Componente de encabezado */}
      <Footer />
    </>
  );
};

export default HomePeople;
