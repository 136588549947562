import React, { useState, useEffect } from "react";
import SammyImg from "../assets/sp2.png";
import LegacyImg from "../assets/1.svg";
import ProyectumImg from "../assets/Home.png";

const BarraApp = () => {
  const [activeApp, setActiveApp] = useState(
    localStorage.getItem("activeApp") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeApp", activeApp); // Guarda la aplicación activa en localStorage
  }, [activeApp]);

  const handleClick = (appName, url) => {
    setActiveApp(appName); // Actualiza el estado de la aplicación activa
    window.location.href = url;
  };

  return (
    <div style={estilos.principal}>
      <h2>Apps developed</h2>
      <div style={estilos.appBar}>
        <div style={estilos.contenedorCirculos}>
          <div style={estilos.itemCirculo}>
            <div
              style={estilos.circulo}
              onClick={() => handleClick("Sammy", "/Sammy/home")}
            >
              <img src={SammyImg} alt="Sammy" style={estilos.imagen} />
            </div>
            <span
              style={activeApp === "Sammy" ? estilos.texto2 : estilos.texto}
            >
              Sammy
            </span>
          </div>

          <div style={estilos.itemCirculo}>
            <div
              style={estilos.circulo}
              onClick={() => handleClick("Legacy", "/Legacy/Home")}
            >
              <img src={LegacyImg} alt="Legacy" style={estilos.imagen} />
            </div>
            <span
              style={activeApp === "Legacy" ? estilos.texto2 : estilos.texto}
            >
              Legacy
            </span>
          </div>

          <div style={estilos.itemCirculo}>
            <div
              style={estilos.circulo}
              onClick={() => handleClick("Proyectum", "/Proyectum/Home")}
            >
              <img src={ProyectumImg} alt="Proyectum" style={estilos.imagen} />
            </div>
            <span
              style={activeApp === "Proyectum" ? estilos.texto2 : estilos.texto}
            >
              Proyectum
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Objeto de estilos para el componente BarraApp
const estilos = {
  appBar: {
    backgroundColor: "white",
    padding: "10px 0",
    textAlign: "center",
    borderBottom: "2px solid #ddd",
    margin: "10px",
    borderRadius: 10,
    BorderTop: "2px solid #ddd",
  },
  titulo: {
    color: "#FFFFFF",
    fontSize: "15px",
    marginBottom: "5px",
  },
  contenedorCirculos: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },
  itemCirculo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  circulo: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  imagen: {
    width: "65px",
    height: "50px",
    borderRadius: "50%",
  },
  texto: {
    fontWeight: "bold",
    fontSize: "1.2em",
    marginTop: "1px",
    textShadow: "1px 1px 5px rgba(0, 0, 0, 0.3)",
    color: "black",
  },
  texto2: {
    color: "gold",
    fontWeight: "bold",
    fontSize: "1.2em",
    marginTop: "10px",
    textShadow: "1px 1px 5px rgba(0, 0, 0, 0.3)",
  },
  principal: {
    alignItems: "center",
    textAlign: "center",
  },
};

export default BarraApp;
