import React from "react";
import Content from "../components/Content";
/* import Gallery from "../components/Gallery"; */
import Footer from "../components/Footer/Footer";
const About = () => {
  return (
    <>
      <Content />
      {/* <Gallery /> */}
      <Footer />
    </>
  );
};

export default About;
