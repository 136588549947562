import React, { useEffect } from "react";
// Rutas
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// Páginas
import Home from "./pagesSammy/Home";
import About from "./pagesSammy/About";
import Contact from "./pagesSammy/Contact";
import HomePeople from "./pagesPeopletech/HomePeople";
import AboutPeople from "./pagesPeopletech/AboutPeopletech";
import ContactPeople from "./pagesPeopletech/ContactPeopletech";
import HomeLegacy from "./pagesLegacy/HomeLegacy";
import HomeProyectum from "./pagesProyectum/HomeProyectum";
// Componentes
import Navbar from "./components/Navbar";
import NavbarP from "./componentsPeopletech/NavbarP";
import NavbarL from "./componentsLegacy/NavbarL";
import NavbarPr from "./componentsProyectumcopy/NavbarPr";
import HomeObraSonora from "./componentsObraSonora/Home.jsx";
function App() {
  const location = useLocation();

  const renderNavbar = () => {
    if (location.pathname.startsWith("/sammy")) {
      return <Navbar />;
    } else if (location.pathname.startsWith("/")) {
      return <NavbarP />;
    } else if (location.pathname.startsWith("/legacy")) {
      return <NavbarL />;
    } else if (location.pathname.startsWith("/proyectum")) {
      return <NavbarPr />;
    } else if (location.pathname.startsWith("/paisajessonorosdelnoa")) {
      return <React.Fragment/>;
    }else {
      return null; // Opcionalmente, puedes renderizar un Navbar por defecto aquí
    }
  };

  // Establecer el título según la ruta
  // Establecer el título y favicon según la ruta
  useEffect(() => {
    const iconLink = document.querySelector("link[rel='icon']");

    if (location.pathname.startsWith("/sammy")) {
      document.title = "Sammy"; // Título para Sammy
      iconLink.href = "favicon.ico"; // Favicon para Sammy
    } else {
      document.title = "PeopleTech"; // Título para PeopleTech
      iconLink.href = "image.ico"; // Favicon para PeopleTech
    }
  }, [location.pathname]); // Ejecuta cada vez que la ruta cambia

  return (
    <>
      {!location.pathname.startsWith("/paisajessonorosdelnoa") && renderNavbar()}
      <Routes>
        {/* Rutas para SAMMY */}
        <Route path="/sammy/about" element={<About />} />
        <Route path="/sammy/home" element={<Home />} />
        <Route path="/sammy/contact" element={<Contact />} />
        
        <Route path="/paisajessonorosdelnoa" element={<HomeObraSonora />} />
        {/* Rutas para PeopleTech */}
        <Route path="/" element={<HomePeople />} />
        <Route path="/about" element={<AboutPeople />} />
        <Route path="/contact" element={<ContactPeople />} />

        <Route path="/legacy/home" element={<HomeLegacy />} />

        <Route path="/proyectum/home" element={<HomeProyectum />} />
      </Routes>
    </>
  );
}

// Envuelve tu App en BrowserRouter
export default function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
