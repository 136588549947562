import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//img
import headerImg from "../assets/iPhone 15.png";

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    // tamanhos
    gap: theme.spacing(2),
    paddingTop: theme.spacing(10),
    // cor de fundo
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      {/*  Box text  */}
      <BoxText component="section">
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: 700,
            color: "black",
          }}
        >
          
The app to create your genius tree and create your biography.
        </Typography>

        <Typography
          variant="p"
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            color: "black",
          }}
        >
         You can save the most important moments of your life and those of your family members. You can also create timelines of these moments.
        </Typography>

  
      </BoxText>

      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "flex-end", // Alínea a la derecha en pantallas grandes
          alignItems: "center",
          height: "100%", // Toma la altura completa del contenedor
          [theme.breakpoints.down("md")]: {
            flex: "1",
            alignSelf: "center",
            justifyContent: "center", // Centra en pantallas pequeñas
          },
          [theme.breakpoints.up("md")]: {
            flex: "2",
            alignSelf: "flex-start", // Mueve la imagen más hacia arriba en pantallas grandes
            paddingTop: theme.spacing(5), // Ajusta la posición vertical
          },
        })}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "110vh", // O la altura que desees
            width: "90vh",
            marginRight: "5rem", // Ajusta la separación del borde derecho
            marginTop: "-20rem", // Ajusta la posición vertical de la imagen
          }}
        >
          <img
            src={headerImg}
            alt="headerImg"
            style={{
              maxWidth: "100%", // Para que la imagen no desborde el contenedor
              maxHeight: "100%", // Para que la imagen mantenga su aspecto
            }}
          />
        </div>
      </Box>
    </CustomBox>
  );
};

export default Header;
