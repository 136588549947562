// HomePeople.js
import React from "react";
import Header from "../componentsLegacy/Header";
import BarraApp from "../componentsPeopletech/Barraaps"; // Asegúrate de que sea el nombre correcto
import Footer from "../componentsLegacy/Footer/Footer";

const HomeLegacy = () => {
  return (
    <>
      <BarraApp />
      <Header /> {/* Componente de encabezado */}
      <Footer />
    </>
  );
};

export default HomeLegacy;
