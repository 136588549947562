import React from "react";
import Content from "../componentsPeopletech/Content";
import Gallery from "../componentsPeopletech/Gallery";
import Footer from "../componentsPeopletech/Footer/Footer";
const About = () => {
  return (
    <>
      <Content />
      <Gallery />
      <Footer />
    </>
  );
};

export default About;
