import React from "react";
import Details from "../components/Details";
import Footer from "../components/Footer/Footer";

const Contact = () => {
  return (
    <>
      <Details />
      <Footer />
    </>
  );
};

export default Contact;
