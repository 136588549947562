import React from "react";
import Details from "../componentsPeopletech/Details";
import Footer from "../componentsPeopletech/Footer/Footer";

const Contact = () => {
  return (
    <>
      <Details />
      <Footer />
    </>
  );
};

export default Contact;
