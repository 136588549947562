import React, { useEffect } from 'react';
import './Home.css';
const Home = () => {
    useEffect(() => {
        const handleScroll = () => {
            const header = document.getElementById("header");
            if (window.scrollY > 50) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }
        };

        window.addEventListener("scroll", handleScroll);
        
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
            <video autoPlay muted loop className="video-background">
                <source src="/Video Final Dai.mp4" type="video/mp4" />
                Tu navegador no soporta la reproducción de video.
            </video>

            {/* Header Translucido */}
            <div className="header" id="header">
                Paisajes sonoros del noroeste Argentino
            </div>

            <div className="content">
                <div className="left-text">
                    Obra sonora que evoca a la representación de paisajes naturales del Noroeste Argentino, mediante la utilización de un sistema de sonido cuadrafónico dispuesto en un salón cerrado. <br />El oyente contempla sonidos que no son propios de una gran urbe y le permiten indagar en sensaciones, escuchas específicas y otros aspectos que construyen reflexión acerca de otras culturas y ecosistemas naturales del país.
                </div>
                <div className="right-text">
                    Artista: Daiana Denise Retamozo
                </div>
            </div>

            <div className="footer">
                {/* Audio en loop en el footer con controles visibles */}
                <audio className="audio-player" controls autoPlay loop>
                    <source src="/audio.mp3" type="audio/mp3" />
                    Tu navegador no soporta la reproducción de audio.
                </audio>
                <p>LEE LA OBRA</p>
                <div className="down-arrow">↓</div>
            </div>

            {/* Contenedor centrado para el visor de PDF */}
            <div className="pdf-container">
                <iframe src="/mi-documento.pdf" className="pdf-viewer" title="Visor de PDF"></iframe>
            </div>
        </div>
    );
};

export default Home;
