import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// Importar la imagen
import headerImg from "../assets/image__2_-removebg-preview.png";

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({

    display: "flex",
    justifyContent: "space-between", // Alineación horizontal
    alignItems: "center", // Centrar verticalmente
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3), // Reducido padding-top
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      {/* Box de texto */}
      <BoxText component="section">
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: 700,
            color: "black",
          }}
        >
          
We create software to make life easier for companies and mainly for people.
        </Typography>

        <Typography
          variant="body1" // Cambiado a body1 para mantener consistencia
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            color: "black",
          }}
        >
         
We are constantly growing, creating new projects and intelligent solutions.
        </Typography>

      {/*   <Box>
          <Button
            component={Link}
            to={"/about"}
            variant="outlined"
            sx={{
              px: 4,
              py: 1,
              fontSize: "0.9rem",
              textTransform: "capitalize",
              borderRadius: 0,
              color: "black",
              backgroundColor: "transparent",
              borderColor: "black",
              "&&:hover": {
                color: "#343a55",
                borderColor: "#343a55",
              },
              "&&:focus": {
                color: "#343a55",
                borderColor: "#343a55",
              },
            }}
          >
            explore
          </Button>
        </Box> */}
      </BoxText>

      {/* Box que contiene la imagen */}
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center", // Centrar horizontalmente
          alignItems: "center", // Centrar verticalmente
          flex: "1", // Ocupar espacio adecuado
          height: "100%", // Tomar altura completa del contenedor
          [theme.breakpoints.down("md")]: {
            flex: "1",
            alignSelf: "center",
            justifyContent: "center", // Centra en pantallas pequeñas
          },
        })}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Para centrar adecuadamente
            width: "75vh", // Mantener el ancho deseado
            margin: "0 auto", // Centrar horizontalmente
          }}
        >
          <img
            src={headerImg}
            alt="headerImg"
            style={{
              maxWidth: "100%", // Para que la imagen no desborde el contenedor
              maxHeight: "100%", // Para que la imagen mantenga su aspecto
            }}
          />
        </div>
      </Box>
    </CustomBox>
  );
};

export default Header;
